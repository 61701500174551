<template>
  <div>
    <div class="tway-header-1 mb-6" style="font-weight: 400">
      Hola {{ name }}, te damos la bienvenida a la plataforma que te guiará por el camino de la Transformación Digital
    </div>
    <v-row class="ma-0 mb-6" no-gutters>
      <v-col class="ma-0" :class="desktop ? 'px-3' : 'mb-4'" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <v-card
          height="100%"
          class="pa-3"
          style="border-radius: 10px; box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.12)"
        >
          <v-row justify="center" align="center" class="ma-0 fill-height">
            <v-col class="ma-0 pa-0" cols="4" xs="4" sm="4" md="4" lg="4" xl="4">
              <v-row class="ma-0" justify="center">
                <v-img :src="require(`@/assets/img/home/card1.svg`)" contain max-height="630" />
              </v-row>
            </v-col>
            <v-col class="ma-0 pa-0" cols="8" xs="8" sm="8" md="8" lg="8" xl="8">
              <v-row class="ma-0 px-2" justify="start">
                <div class="full-width">
                  <span class="text-card">
                    Estás en el Plan
                    {{ plan }}.<br />
                  </span>
                  <span class="text-card"> Descubre todos nuestros planes. </span>
                </div>
                <v-btn text color="tway-violet" class="button-text px-0 mt-1" :to="{ name: 'plans' }">
                  Ver detalle
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col class="ma-0" :class="desktop ? 'px-3' : 'mb-4'" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <v-card
          height="100%"
          class="pa-3"
          style="border-radius: 10px; box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.12)"
        >
          <v-row justify="center" align="center" class="ma-0 fill-height">
            <v-col class="ma-0 pa-0" cols="4" xs="4" sm="4" md="4" lg="4" xl="4">
              <v-row class="ma-0" justify="center">
                <v-progress-circular :rotate="360" :size="70" :width="15" :value="percentCompany" color="#16C62E">
                  {{ percentCompany }}
                </v-progress-circular>
              </v-row>
            </v-col>
            <v-col class="ma-0 pa-0" cols="8" xs="8" sm="8" md="8" lg="8" xl="8">
              <v-row class="ma-0" justify="start">
                <span class="text-card">Completa tu perfil y avanza en tu camino hacia la Transformación Digital.</span>
                <v-btn
                  text
                  color="tway-violet"
                  class="button-text px-0 mt-1"
                  :disabled="percentCompany >= 100"
                  :to="{ name: 'my-profile' }"
                >
                  <span v-if="percentCompany < 100">Completar mi perfil</span>
                  <span v-else>Completo</span>
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col class="ma-0" :class="desktop ? 'px-3' : 'mb-4'" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <v-card
          height="100%"
          class="pa-3"
          style="border: 1px solid #7319d5; border-radius: 10px; box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.12)"
        >
          <v-row justify="center" align="center" class="ma-0 fill-height">
            <v-col class="ma-0 pa-0" cols="4" xs="4" sm="4" md="4" lg="4" xl="4">
              <v-row class="ma-0" justify="center">
                <v-progress-circular :rotate="360" :size="70" :width="15" :value="percentageImtd" color="#7319D5">
                  {{ percentageImtd }}
                </v-progress-circular>
              </v-row>
            </v-col>
            <v-col class="ma-0 pa-0" cols="8" xs="8" sm="8" md="8" lg="8" xl="8">
              <v-row class="ma-0" justify="start">
                <span class="text-card">
                  Completa nuestra encuesta y conoce tu Índice de Madurez en Transformación Digital.
                </span>
                <v-btn
                  text
                  color="tway-violet"
                  class="button-text px-0 mt-1"
                  :disabled="percentageImtd == 100"
                  :to="{ name: 'dti-form' }"
                >
                  <span v-if="percentageImtd != 100">Completar encuesta</span>
                  <span v-if="percentageImtd == 100">Completo</span>
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="ma-0 mb-6" no-gutters>
      <v-col class="ma-0" :class="desktop ? 'px-3' : 'mb-4'" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <v-card
          height="100%"
          class="pa-3"
          style="border-radius: 10px; box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.12)"
        >
          <v-row justify="center" align="center" class="ma-0 fill-height">
            <v-col class="ma-0 pa-0" cols="4" xs="4" sm="4" md="4" lg="4" xl="4">
              <v-row class="ma-0" justify="center">
                <v-img
                  v-if="percentageImo == 0"
                  :src="require(`@/assets/img/imo/undraw_imo.png`)"
                  contain
                  max-height="630"
                />
                <v-progress-circular
                  v-if="percentageImo > 0"
                  :rotate="360"
                  :size="70"
                  :width="15"
                  :value="percentageImo"
                  color="#E30513"
                >
                  {{ percentageImo }}
                </v-progress-circular>
              </v-row>
            </v-col>
            <v-col class="ma-0 pa-0" cols="8" xs="8" sm="8" md="8" lg="8" xl="8">
              <v-row class="ma-0" justify="start">
                <span class="text-card"> ¿Quieres conocer el Índice de Madurez Organizacional de tu empresa? </span>
                <v-btn
                  text
                  color="tway-violet"
                  class="button-text px-0 mt-1"
                  :disabled="percentageImo == 100"
                  :to="{ name: 'survey-imo' }"
                >
                  <span v-if="percentageImo != 0 && percentageImo != 100">Completar encuesta</span>
                  <span v-if="percentageImo == 0">Realizar encuesta</span>
                  <span v-if="percentageImo == 100">Completo</span>
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "HomeComponent",

  props: {
    name: {
      type: String,
      default: "",
      required: true,
    },
    plan: {
      type: String,
      default: "",
      required: true,
    },
    percentageImtd: {
      type: Number,
      default: 0,
      required: true,
    },
    percentageImo: {
      type: Number,
      default: 0,
      required: true,
    },
    percentCompany: {
      type: Number,
      default: 0,
      required: true,
    },
  },

  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>
