<template>
  <v-hover v-slot:default="{ hover }">
    <router-link :to="{ name: 'trends-home-post', params: { id: post.id } }" class="image-trend-post-card d-flex">
      <v-img v-if="post.thumbnail" :src="post.thumbnail" class="rounded" :class="hover ? 'elevation-6' : null">
        <v-row no-gutters align="end" class="lightbox white--text pa-5 fill-height image-trend-post-card-gradient">
          <v-col>
            <div class="image-trend-post-card-title mb-2">
              {{ post.title }}
            </div>
            <div class="image-trend-post-card-subtitle">
              {{ post.attribution[0].author }}
            </div>
          </v-col>
        </v-row>
      </v-img>
    </router-link>
  </v-hover>
</template>

<script>
export default {
  name: "ImageTrendPostCard",

  props: {
    post: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
.image-trend-post-card {
  color: initial;
  text-decoration: none;
}

.image-trend-post-card-gradient {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.62) 100%);
}

.image-trend-post-card-title {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
}

.image-trend-post-card-subtitle {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
}
</style>
