<template>
  <v-hover v-slot:default="{ hover }">
    <v-card :elevation="hover ? 6 : null" :to="{ name: 'trends-home-post-getabstract', params: { id: data.id } }">
      <div class="d-flex flex-no-wrap justify-space-between">
        <v-container fluid v-if="!data.id">
          <v-progress-circular indeterminate color="grey lighten-1" />
        </v-container>
        <v-avatar v-if="left" class="ma-3" tile>
          <v-img :src="data.coverUrl" cover class="rounded" />
        </v-avatar>

        <div v-if="data.id" class="d-flex flex-column w-100">
          <v-card-subtitle
            class="horizontal-trend-post-card-date pb-2 black--text"
            :class="left ? 'pl-0' : 'pr-0'"
            v-text="getDate(data.activationTimestamp)"
          />
          <v-card-title
            class="horizontal-trend-post-card-title py-0"
            :class="left ? 'pl-0' : 'pr-0'"
            v-text="data.title"
          />
          <v-card-text class="horizontal-trend-post-card-author mt-auto" :class="left ? 'pl-0' : 'pr-0'">
            Autor: {{ data.author }}
          </v-card-text>
        </div>

        <v-avatar v-if="!left" class="ma-3" size="130" tile>
          <v-img :src="data.coverUrl" cover height="130" class="rounded" />
        </v-avatar>
      </div>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: "HorizontalTrendPostCardGetAbstract",

  props: {
    post: {
      type: [Number, String],
      required: true,
    },

    left: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },

  methods: {
    getDate(timestamp) {
      let unix_timestamp = timestamp;
      var date = new Date(unix_timestamp);

      return date.toLocaleDateString("es", {
        year: "numeric",
        month: "2-digit",
        day: "numeric",
      });
    },
  },
};
</script>

<style>
.horizontal-trend-post-card-title {
  font-family: "Josefin Sans", sans-serif;
  font-style: normal;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 15px !important;
  word-break: break-word !important;
}

.horizontal-trend-post-card-date {
  font-family: "Josefin Sans", sans-serif;
  font-style: normal;
  font-weight: bold !important;
  font-size: 11px !important;
  line-height: 11px !important;
  text-transform: uppercase;
}

.horizontal-trend-post-card-author {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;

  color: #615d5d;
}
</style>
