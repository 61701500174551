<template>
  <div class="trends-page">
    <div class="tway-header-2">Conoce lo último en Transformación</div>
    <div class="tway-title-hero" />
    <div :class="mobile ? 'pt-4' : ''" class="trends">
      <v-container fluid>
        <v-layout row>
          <v-flex class="px-3" xs12 md4>
            <div :class="mobile ? 'text-center' : ''" class="tway-headline-3 mb-3">Actualidad</div>
            <div v-for="(getAbstract, n) in gaPosts.slice(0, 1)" :key="getAbstract.source">
              <horizontal-trend-post-card-getabstract
                v-if="getAbstract.flag == 'SMALL' && getAbstract.sourceType == 'GETABSTRACT'"
                :left="n % 2 !== 0"
                class="mb-5"
                :post="getAbstract"
              />
              <image-trend-post-card-getabstract
                v-if="getAbstract.flag != 'SMALL' && getAbstract.sourceType == 'GETABSTRACT'"
                class="mb-5"
                :post="getAbstract"
              />
            </div>
          </v-flex>
          <v-flex class="px-3" xs12 md4>
            <div class="tway-headline-3 white--text mb-3">.</div>

            <div v-for="(current, n) in staticPosts.current.slice(0, 2)" :key="current.id">
              <card
                v-if="current.flag == 'SMALL' && current.sourceType != 'GETABSTRACT'"
                :left="n % 2 !== 0"
                class="mb-5"
                :post="current"
              />
              <image-trend-post-card
                v-if="current.flag != 'SMALL' && current.sourceType != 'GETABSTRACT'"
                class="mb-5"
                :post="current"
              />
            </div>
          </v-flex>
          <v-flex class="px-3" :class="mobile ? 'mb-15' : ''" xs12 md4>
            <div :class="mobile ? 'text-center' : ''" class="tway-headline-3 mb-3">Opinión</div>
            <div v-for="(current, n) in staticPosts.opinion.slice(0, 2)" :key="current.id">
              <card
                v-if="current.flag == 'SMALL' && current.sourceType != 'GETABSTRACT'"
                :left="n % 2 !== 0"
                class="mb-5"
                :post="current"
              />
              <image-trend-post-card
                v-if="current.flag != 'SMALL' && current.sourceType != 'GETABSTRACT'"
                class="mb-5"
                :post="current"
              />
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <div class="tway-waves tway-waves-white-up" />
    <div class="quote">
      <v-container fluid class="text-center">
        <v-btn
          style="height: 36px; font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
          :to="{ name: 'trends' }"
          color="tway-violet"
          class="white--text roboto"
          x-large
          rounded
        >
          ir a tendencias
        </v-btn>
      </v-container>
    </div>
  </div>
</template>

<script>
import HorizontalTrendPostCard from "@/components/trends/trendCard/HorizontalTrendPostCard";
import ImageTrendPostCard from "@/components/trends/trendCard/ImageTrendPostCard";
import HorizontalTrendPostCardGetAbstract from "@/components/trends/trendCard/HorizontalTrendPostCardGetAbstract";
import ImageTrendPostCardGetAbstract from "@/components/trends/trendCard/ImageTrendPostCardGetAbstract";

export default {
  name: "HomeTrends",

  components: {
    card: HorizontalTrendPostCard,
    "horizontal-trend-post-card-getabstract": HorizontalTrendPostCardGetAbstract,
    "image-trend-post-card-getabstract": ImageTrendPostCardGetAbstract,
    ImageTrendPostCard,
  },

  props: {
    staticPosts: {
      type: Object,
      required: true,
    },
    gaPosts: {
      type: Array,
      required: true,
    },
  },

  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
  },
};
</script>
