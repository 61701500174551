<template>
  <v-hover v-slot:default="{ hover }">
    <a
      target="_blank"
      :href="`https://www.getabstract.com/es/resumen/${trendName}/${post.source}`"
      class="image-trend-post-card d-flex"
    >
      <v-img :src="post.thumbnail" class="rounded" :class="hover ? 'elevation-6' : null" :aspect-ratio="350 / 260">
        <v-row no-gutters align="end" class="lightbox white--text pa-5 fill-height image-trend-post-card-gradient">
          <v-container fluid v-if="!post.source">
            <v-progress-circular indeterminate color="grey lighten-1" />
          </v-container>
          <v-col>
            <div class="image-trend-post-card-title mb-2">
              {{ post.title }}
            </div>
            <div class="image-trend-post-card-subtitle">
              {{ post.author }}
            </div>
          </v-col>
        </v-row>
      </v-img>
    </a>
  </v-hover>
</template>

<script>
export default {
  name: "ImageTrendPostCardGetAbstract",

  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  computed: {
    trendName() {
      const result = this.post.title.toLowerCase().replaceAll(" ", "-");
      return result;
    },
  },
  methods: {
    getDate(timestamp) {
      let unix_timestamp = timestamp;
      var date = new Date(unix_timestamp);

      return date.toLocaleDateString("es", {
        year: "numeric",
        month: "2-digit",
        day: "numeric",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.image-trend-post-card {
  color: initial;
  text-decoration: none;
}

.image-trend-post-card-gradient {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.62) 100%);
}

.image-trend-post-card-title {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
}

.image-trend-post-card-subtitle {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
}
a {
  text-decoration: none;
}
</style>
