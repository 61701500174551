<template>
  <div>
    <Loader :visible="isLoading" />
    <div v-if="!isLoading">
      <HomeContent
        :name="getUmapiUser.firstName"
        :plan="getSubscription.plan.name"
        :percentage-imtd="percentage"
        :percentage-imo="percentageImo"
        :percent-company="getCompanyCompletion"
      />
      <HomeTrends :static-posts="getStaticTrends" :ga-posts="getGATrends" />
    </div>
  </div>
</template>
<script>
import HomeContent from "@/components/home/HomeContent";
import HomeTrends from "@/components/home/HomeTrends";
import Loader from "@/components/Loader";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "Home",

  components: {
    HomeContent,
    HomeTrends,
    Loader,
  },
  data: () => ({
    isLoading: true,
  }),
  async created() {
    Promise.all([
      this.dtInit(),
      this.companyStoreInit(),
      this.setCompanyProfile(),
      this.setUmapiUserData(),
      this.setSubscription(),
      this.setStaticTrends(),
      this.setGATrends(),
    ])
      .then(async () => {
        await this.surveyComplete(this.getUmapiUser.id);
        this.setCompanyCompletion();
      })
      .catch((error) => console.log(error))
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    ...mapActions("DigitalTransformationStore", { dtInit: "init" }),
    ...mapActions("DigitalTransformationStore", ["surveyComplete"]),
    ...mapActions("CompanyStore", { companyStoreInit: "init" }),
    ...mapActions("CompanyStore", ["setCompanyProfile", "setCompanyCompletion"]),
    ...mapActions("UserStore", ["setUmapiUserData"]),
    ...mapActions("subscription", ["setSubscription"]),
    ...mapActions("TrendStore", ["setGATrends", "setStaticTrends"]),
  },
  computed: {
    ...mapGetters("DigitalTransformationStore", ["percentage", "percentageImo"]),
    ...mapGetters("CompanyStore", ["getCompanyType", "getCompany", "getCompanyCompletion"]),
    ...mapGetters("UserStore", ["getUmapiUser"]),
    ...mapGetters("subscription", ["getSubscription"]),
    ...mapGetters("TrendStore", ["getStaticTrends", "getGATrends"]),
  },
};
</script>
